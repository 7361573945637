import React from 'react'

const Terms = () => {
  return (
    <div style={{ margin: 50 }}>
      <h1>เงื่อนไขการใช้งาน AIYA แชทบอท</h1>
      <h2>1. ข้อมูลเบื้องต้น</h2>
      <dd>
        1.1. บริษัท มีจีเนียส จำกัด (“บริษัท”) ขอให้ท่าน
        โปรดศึกษารายละเอียดและเงื่อนไขการให้บริการของ AIYA แชทบอท ก่อนเข้าร่วมใช้ AIYA แชทบอท
        หรือเปิดบัญชีผู้ใช้ส่วนตัวของท่านในระบบของ AIYA แชทบอท(“บัญชีผู้ใช้”)
        เพื่อท่านจะได้ทราบถึงสิทธิและข้อตกลงที่มีผลผูกพันตามกฎหมายระหว่างท่านกับ บริษัท มีจีเนียส
        จำกัด ในบริการ ที่บริษัทให้บริการหรือที่ได้จัดไว้ให้รวมถึง
        <dd>
          1.1.1. AIYA แชทบอท
          <br />
          1.1.2. บริการที่จัดไว้ให้โดย AIYA แชทบอท รวมทั้งโปรแกรมและซอฟต์แวร์ที่เกี่ยวข้อง
          ซึ่งทำให้ท่านใช้งานได้ผ่านทาง AIYA แชทบอท และ
          <br />
          1.1.3. ข้อมูลข่าวสารทั้งหมด ข้อมูลที่เชื่อมโยง คุณสมบัติ ข้อมูล ข้อความสั้นๆ รูปภาพ
          ภาพถ่าย กราฟิก ไอคอน เพลง เสียง วิดีโอ (รวมถึงการทำ live stream) ข้อความ tag เนื้อหา
          โปรแกรม ซอฟต์แวร์ บริการแอพพลิเคชัน รวมถึงโดยไม่จำกัดเพียงบริการแอพพลิเคชันบนอุปกรณ์
          เครื่องมือสื่อสาร หรืออุปกรณ์อื่นใด ที่ทำให้ท่านใช้งานได้ผ่านทาง AIYA แชทบอท
          หรือบริการที่เกี่ยวข้องกับ AIYA แชทบอท ("เนื้อหา") คุณสมบัติใหม่ใดๆ
          ที่เพิ่มไปยังหรือเสริมให้กับบริการจะอยู่ภายใต้เงื่อนไขการให้บริการนี้ด้วยเช่นกัน
        </dd>
        1.2. บริการของบริษัท
        เป็นบริการที่ผู้ใช้บริการสามารถสร้างแชทบอทเป็นของตนเองเพื่อช่วยในการทำธุรกิจ
        โดยบริษัทอาจเรียกผู้ใช้บริการว่า ท่าน หรือ ผู้ใช้
        <br />
        1.3. การสมัครเข้าใช้งาน AIYA แชทบอท ท่านจะต้องอ่านและยอมรับเงื่อนไข รายละเอียด
        ข้อกำหนดและนโยบายทั้งหมดใน AIYA แชทบอท รวมทั้งข้อกำหนด นโยบายและ/หรือเงื่อนไขต่าง ๆ
        ของแพลตฟอร์มภายนอกที่เชื่อมโยงกับ AIYA แชทบอท
        นอกจากนั้นท่านจะต้องยอมรับนโยบายการดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านตามที่ได้มีการอธิบายไว้ในนโยบายความเป็นส่วนตัว
        <br />
        1.4. AIYA แชทบอท สงวนสิทธิ์ในการเปลี่ยนแปลง ดัดแปลง ระงับ หรือยกเลิก AIYA แชทบอท
        หรือบริการทั้งหมดหรือบางส่วนได้ตลอดเวลา ทั้งนี้ AIYA แชทบอท
        อาจออกบริการบางอย่างหรือคุณสมบัติของบริการเพิ่มเติม ซึ่งอาจทำให้ AIYA แชทบอท
        ไม่สามารถทำงานได้อย่างสมบูรณ์ในบางสถานการณ์
        หรือให้บริการในลักษณะเดียวกันกับเวอร์ชั่นล่าสุดของ AIYA แชทบอท ซึ่งท่านจะไม่เรียกร้องให้
        AIYA แชทบอทรับผิดในสถานการณ์ดังกล่าว นอกจากนั้น AIYA แชท บอท
        ยังอาจกำหนดขอบเขตสำหรับคุณสมบัติบางอย่าง หรือจำกัดการเข้าถึงบางส่วนหรือทั้งหมดของ AIYA
        แชทบอท หรือจำกัดการให้บริการภายใต้ดุลยพินิจของบริษัทแต่เพียงผู้เดียว
        และโดยไม่ต้องรับผิดหรือแจ้งให้ท่านทราบล่วงหน้า
        <br />
        1.5. ในกรณีที่ท่านไม่เห็นด้วยกับเงื่อนไขการใช้งานนี้ โปรดงดใช้บริการหรือเข้าถึง AIYA แชทบอท
        ของบริษัท หรือหากท่านอายุต่ำกว่า 20 ปีหรือยังไม่บรรลุนิติภาวะตามกฎหมายในประเทศไทย
        (“ผู้เยาว์”) ซึ่งทำให้ท่านไม่สามารถที่จะให้คำยินยอมต่อข้อกำหนดนี้ได้
        ท่านจะต้องขออนุญาตจากบิดามารดาหรือผู้ปกครองตามกฎหมายของท่าน
        เพื่อให้บิดามารดาหรือผู้ปกครองเป็นผู้ดำเนินการให้คำยินยอมสำหรับการเปิดบัญชีผู้ใช้กับ AIYA
        แชทบอท
        ทั้งนี้บิดามารดาหรือผู้ปกครองตามกฎหมายของผู้เยาว์จะต้องยอมรับข้อกำหนดของข้อตกลงนี้แทนผู้เยาว์และท่านจะต้องรับผิดชอบต่อการใช้งานบัญชีผู้ใช้หรือบริการใดๆของ
        AIYA แชทบอท ทั้งหมดจากการใช้บัญชีผู้ใช้ดังกล่าวของผู้เยาว์
        ไม่ว่าบัญชีผู้ใช้ดังกล่าวจะเปิดอยู่ในปัจจุบันหรือได้มีการสร้างบัญชีผู้ใช้ขึ้นใหม่ในอนาคต
      </dd>
      <h2>2. ความเป็นส่วนตัว</h2>
      <dd>
        2.1. AIYA แชทบอท ให้ความสำคัญกับนโยบายความเป็นส่วนตัวของท่าน
        เพื่อปกป้องและคุ้มครองข้อมูลส่วนบุคคลของท่าน เราจึงได้จัดทำนโยบายความเป็นส่วนตัวของ AIYA
        แชทบอท เพื่ออธิบายแนวทางปฏิบัติด้านความเป็นส่วนตัว
        ทางบริษัทขอให้ท่านศึกษานโยบายความเป็นส่วนตัวเพื่อให้เข้าใจว่า AIYA แชทบอท เก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลที่เกี่ยวกับบัญชีผู้ใช้ของท่านและ/หรือการใช้งานบริการของท่าน
        (“ข้อมูลของผู้ใช้”) เพื่อวัตถุประสงค์ที่เกี่ยวข้องกับการใช้บริการ AIYA แชทบอท
        และเพื่อสิทธิประโยชน์ให้แก่ผู้ใช้ที่อาจเกิดขึ้นในอนาคตเท่านั้น
        <br />
        2.2. ท่านในฐานะผู้ใช้ AIYA แชทบอท รับทราบและยินยอมตามนโยบายและเงื่อนไขดังต่อไปนี้
        <dd>
          2.2.1. ยินยอมให้ AIYA แชทบอท เก็บรวบรวม ใช้ เปิดเผย และ/หรือดำเนินการกับเนื้อหา
          ข้อมูลส่วนบุคคลของท่านและข้อมูลของผู้ใช้ตามที่อธิบายไว้ในนโยบายความเป็นส่วนตัว
          <br />
          2.2.2. ตกลงและยอมรับว่าสิทธิการเป็นเจ้าของในข้อมูลของผู้ใช้เป็นของ AIYA แชทบอท
          และเป็นของท่านร่วมกัน
          <br />
          2.2.3. ผู้ใช้ที่ครอบครองข้อมูลส่วนบุคคลของผู้ใช้คนอื่นผ่านการใช้บริการ (“ฝ่ายผู้รับ”)
          ตกลงว่าจะ
          <br />
          <dd>
            2.2.3.1.
            ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่บังคับใช้ทั้งหมดอันเกี่ยวกับข้อมูลดังกล่าว
            <br />
            2.2.3.2. อนุญาตให้ผู้ใช้ ซึ่งฝ่ายผู้รับได้เก็บรวบรวมข้อมูลส่วนบุคคลไว้
            (“ฝ่ายผู้เปิดเผย”) ลบข้อมูลของตนที่เก็บรวบรวมไว้ออกจากฐานข้อมูลของฝ่ายผู้รับ และ
            <br />
            2.2.3.3.
            อนุญาตให้ฝ่ายผู้เปิดเผยตรวจสอบว่าฝ่ายผู้รับมีการเก็บรวบรวมข้อมูลใดบ้างเกี่ยวกับตน
            ในแต่ละกรณีของข้อ (2) และข้อ (3) ข้างต้น ตามและเมื่อกฎหมายที่บังคับใช้กำหนดไว้
          </dd>
          2.2.4. ท่านสามารถศึกษานโยบายความเป็นส่วนตัวของ AIYA แชทบอท ได้ที่ https://aiya.ai
          และหากท่านมีข้อสงสัยในนโยบายความเป็นส่วนตัวของ AIYA แชทบอท ท่านสามารถติดต่อสอบถามได้ที่ 02
          821 5699 และสามารถแจ้งขอใช้สิทธิตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
          กำหนดไว้ได้ที่ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัท (DPO) ได้ที่ อีเมล
          hello@aiya.ai หรือติดต่อมายังบริษัทที่ บริษัท มีจีเนียส จำกัด อยู่ที่ 188
          อาคารสปริงทาวเวอร์ (วีเวิร์ค ชั้น11) ถนนพญาไท แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพมหานคร
          รหัสไปรษณีย์ 10400
        </dd>
      </dd>
      <h2>3. ข้อจำกัดสิทธิในการใช้งาน</h2>
      <dd>
        3.1. AIYA แชทบอท
        มอบสิทธิการใช้งานแบบจำกัดและอาจเพิกถอนการเข้าถึงได้ให้แก่ท่านสำหรับเงื่อนไขการให้บริการที่เกี่ยวข้องกับ
        เครื่องหมายการค้า เครื่องหมายบริการ ชื่อตราสินค้า ชื่อทางการค้า โลโก้ แบรนด์ ลิขสิทธิ์
        เนื้อหา บทความและทรัพย์สินทางปัญญาอื่น ๆ (“ทรัพย์สินทางปัญญา”) ที่แสดงใน AIYA แชทบอท
        ทั้งหมดเป็นทรัพย์สินของ AIYA แชทบอท
        หรืออาจเป็นทรัพย์สินของบุคคลภายนอกที่ถือกรรมสิทธิ์ตามที่ระบุไว้ใน AIYA แชทบอท
        เพื่อวัตถุประสงค์การใช้ AIYA แชทบอท เท่านั้น
        บริษัทไม่มีนโยบายหรือเจตนามอบสิทธิหรือสิทธิ์การใช้งานไม่ว่าบางส่วนหรือทั้งหมดทั้งโดยตรงหรือโดยอ้อมแก่ผู้ใช้ที่เข้าถึง
        AIYA แชทบอท เพื่อการใช้งานที่ละเมิด หรือทำการลอกเลียนแบบทรัพย์สินทางปัญญาใด ๆ
        หรือขัดกับกฎหมายใด ๆ และผู้ใช้ AIYA แชทบอทจะไม่สามารถอ้างสิทธิ กรรมสิทธิ์ หรือผลประโยชน์ใน
        AIYA แชทบอท นี้ได้เนื่องจากการได้รับสิทธิในการใช้หรือเข้าถึงบริการ
        <br />
        3.2. ท่านตกลงที่จะปฏิบัติตามกฎหมายทรัพย์สินทางปัญญา ทั้งในเรื่องของ ลิขสิทธิ์
        เครื่องหมายการค้า เครื่องหมายบริการ ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        พระราชบัญญัติเครื่องหมายการค้า พ.ศ. 2534 พระราชบัญญัติลิขสิทธิ์ พ.ศ.2537
        พระราชบัญญัติสิทธิบัตร พ.ศ. 2522 และกฎหมายที่บังคับใช้อื่น ๆ ทั้งหมด ที่คุ้มครองบริการ AIYA
        แชทบอท และเนื้อหาใน AIYA แชทบอท ท่านตกลงที่จะไม่คัดลอก ทำสำเนา แจกจ่าย เผยแพร่ต่อ ส่งต่อ
        แสดงแก่สาธารณะ ดำเนินการต่อสาธารณะ ดัดแปลง ปรับเปลี่ยน ให้เช่า ขาย
        หรือสร้างผลงานจากส่วนหนึ่งส่วนใดของบริการ AIYA แชท บอท หรือเนื้อหาใน AIYA แชทบอท นอกจากนี้
        ท่านตกลงว่าจะไม่ใช้ระบบหรืออุปกรณ์อัตโนมัติอื่นใด
        หรือกระบวนการอื่นที่มิใช่การทำงานด้วยระบบอัตโนมัติเพื่อเฝ้าติดตามหรือคัดลอกเนื้อหาของบริษัท
        โดยไม่ได้รับคำยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากบริษัทก่อน
      </dd>
      <h2>4. โปรแกรมและซอฟแวร์</h2>
      <dd>
        โปรแกรมและซอฟต์แวร์ที่บริษัทจัดไว้ให้ท่านเป็นส่วนหนึ่งของบริการนี้
        และอยู่ภายใต้ข้อกำหนดของเงื่อนไขการให้บริการ AIYA แชทบอท
        บริษัทขอสงวนสิทธิ์ทั้งหมดต่อโปรแกรมและซอฟต์แวร์ที่บริษัทไม่ได้ให้อนุญาตไว้อย่างชัดแจ้ง
      </dd>
      <h2>5. บัญชีผู้ใช้และความปลอดภัยในการใช้งานบัญชี</h2>
      <dd>
        5.1. การเข้าใช้บริการของ AIYA แชทบอท และการสร้างบัญชีผู้ใช้ AIYA แชทบอท
        จำเป็นต้องมีการลงทะเบียนบัญชีผู้ใช้โดยเลือกรหัสประจำตัวผู้ใช้ที่ไม่ซ้ำกัน ("ID ผู้ใช้")
        และสร้างรหัสผ่าน
        บริษัทมีสิทธิแต่เพียงผู้เดียวที่จะใช้ดุลยพินิจในการระงับหรือยกเลิกบัญชีของท่านได้
        หากบริษัทพบว่าท่านเลือกสร้างหรือลงทะเบียน ID ผู้ใช้
        ที่ไม่เหมาะสมที่อาจสร้างความไม่พอใจหรือความแตกแยก
        หรือท่านอาจใช้บัญชีผู้ใช้เพื่อเข้าถึงผลิตภัณฑ์ เว็บไซต์ หรือบริการอื่น ๆ
        ซึ่งบริษัทอนุญาตให้เข้าถึงหรือบริษัทได้เชื่อมต่อกับแพลตฟอร์มของบุคคลภายนอก
        ทั้งนี้บริษัทไม่ได้มีหน้าที่ตรวจสอบและไม่อาจรับผิดชอบต่อเนื้อหา ฟังก์ชั่นการทำงาน
        ความปลอดภัย บริการ นโยบายความเป็นส่วนตัว หรือการปฏิบัติอื่น ๆ ของผลิตภัณฑ์ เว็บไซต์
        หรือบริการที่จัดทำโดยบุคคลภายนอกเหล่านั้น
        กรณีมีความขัดแย้งระหว่างเงื่อนไขการให้บริการของผลิตภัณฑ์ เว็บไซต์บริการ
        รวมถึงนโยบายความเป็นส่วนตัวที่จัดทำโดยบุคคลภายนอก
        บริษัทขอสงวนสิทธิ์เลือกบังคับใช้เงื่อนไขหรือนโยบายตามดุลยพินิจของบริษัทแต่เพียงฝ่ายเดียว
        <br />
        5.2. ท่านตกลงและรับทราบว่าท่านจะต้อง
        <dd>
          5.2.1. เก็บรักษารหัสผ่านไว้เป็นความลับและไม่เผยแพร่หรือเปิดเผยให้บุคคลอื่นทราบ
          <br />
          5.2.2. ตรวจสอบให้แน่ใจว่าท่านออกจากระบบบัญชีเมื่อสิ้นสุดการใช้งาน AIYA แชทบอท
          <br />
          5.2.3. แจ้งบริษัทในทันทีหากมีการใช้บัญชีผู้ใช้ทั้งในส่วน ID ผู้ใช้
          และ/หรือรหัสผ่านของท่านโดยไม่ได้รับอนุญาต
          <br />
          5.2.4. ตรวจสอบให้แน่ใจว่าข้อมูลบัญชีของท่านถูกต้องและเป็นปัจจุบันอยู่เสมอ ทั้งนี้
          ท่านต้องรับผิดชอบต่อการดำเนินการใด ๆ และกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้ ID
          ผู้ใช้และบัญชีผู้ใช้ของท่าน
          แม้ว่าท่านจะไม่ได้ทำกิจกรรมหรือทำการใช้งานดังกล่าวด้วยตนเองก็ตาม
          <br />
          5.2.5. ท่านจะต้องใช้เฉพาะ ID ผู้ใช้และรหัสผ่านของท่านเมื่อเข้าสู่ระบบ
          บริษัทจะไม่รับผิดต่อความสูญเสียหรือความเสียหายที่เกิดจากการใช้รหัสผ่านของท่านโดยไม่ได้อนุญาต
          หรือการที่ท่านไม่ปฏิบัติตามเงื่อนไขนี้
        </dd>
        5.3. ท่านรับทราบและตกลงว่าบริษัทมีสิทธิแต่เพียงผู้เดียวที่จะใช้ดุลยพินิจ
        เพื่อยกเลิกบัญชีผู้ใช้และ ID ผู้ใช้ของท่านทันที
        และลบหรือนำออกจากระบบซึ่งเนื้อหาใดก็ตามที่เกี่ยวข้องกับบัญชีผู้ใช้หรือ ID
        ผู้ใช้ของท่านออกจาก AIYA แชทบอท
        โดยไม่ต้องแจ้งให้ทราบล่วงหน้าหรือรับผิดต่อท่านหรือบุคคลภายนอกใด ๆ
        เหตุผลสำหรับการยกเลิกดังกล่าวอาจได้แก่ แต่ไม่จำกัดเพียง
        <dd>
          5.3.1. การไม่เข้าใช้งานบัญชีผู้ใช้เป็นระยะเวลาต่อเนื่อง
          จนอาจคาดหมายได้ว่าท่านไม่ประสงค์จะใช้งานอีกต่อไป
          <br />
          5.3.2. ละเมิดเงื่อนไขการให้บริการนี้
          <br />
          5.3.3. มีพฤติกรรมที่พิจารณาได้ว่าอาจก่อให้เกิดการกระทำที่ผิดกฎหมาย ฉ้อฉล ล่วงละเมิด
          ทำให้เสื่อมเสีย คุกคาม หรือขู่เข็ญ
          <br />
          5.3.4. ลงทะเบียนหรือเข้าใช้บัญชีผู้ใช้หลายบัญชี โดยไม่มีเหตุอันสมควร
          <br />
          5.3.5. การใช้ส่วนลด หรือ คูปองส่วนลดใด ๆที่อาจได้รับจากทางบริษัท ในทางที่ผิด
          <br />
        </dd>
        5.4. ในกรณีที่บริษัทพบพฤติกรรมที่เป็นอันตรายต่อผู้ใช้ท่านอื่น บุคคลภายนอก
        หรือผลประโยชน์ทางธุรกิจของบริษัท หรือมีการใช้บัญชีผู้ใช้เพื่อจุดประสงค์ที่ผิดกฎหมาย ฉ้อฉล
        ล่วงละเมิด ทำให้เสื่อมเสีย คุกคาม หรือขู่เข็ญ
        บริษัทมีสิทธิ์และอำนาจที่จะดำเนินการแจ้งให้หน่วยงานที่บังคับใช้กฎหมายทราบโดยไม่ต้องแจ้งแก่ท่านให้ทราบล่วงหน้า
        หากเกิดข้อพิพาททางกฎหมายหรือการดำเนินคดีเกี่ยวกับบัญชีผู้ใช้หรือการใช้บริการของท่าน
        ไม่ว่าด้วยเหตุผลใดก็ตามบริษัทอาจยกเลิกบัญชีผู้ใช้ของท่านทันทีโดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า
        <br />
        5.5. ผู้ใช้สามารถยกเลิกบัญชีผู้ใช้ของตนได้ผ่านการแจ้งให้บริษัท ทราบเป็นลายลักษณ์อักษร
        โดยแจ้งมาที่อีเมล hello@aiya.ai และระบุว่าประสงค์ที่จะขอระงับหรือยกเลิกการใช้บริการดังกล่าว
        ทั้งแม้ผู้ใช้ได้ดำเนินการยกเลิกบัญชีของตนแล้ว
        ผู้ใช้ยังคงต้องรับผิดชอบและรับผิดต่อธุรกรรมใดก็ตามที่ยังไม่เสร็จสิ้นสมบูรณ์
        ไม่ว่าธุรกรรมดังกล่าวจะเกิดขึ้นก่อนหรือหลังการยกเลิกบัญชีผู้ใช้ตามเงื่อนไขข้างต้น
        โดยผู้ใช้จะต้องดำเนินการและทำธุรกรรมดังกล่าวให้เรียบร้อยทั้งหมด
        และผู้ใช้จะต้องแจ้งให้บริษัททราบถึงผลสำเร็จดังกล่าวตามเงื่อนไขการให้บริการนี้
        บริษัทไม่มีหน้าที่ต้องรับผิดชอบและจะไม่รับผิดต่อความเสียหายที่เกิดขึ้นอันเนื่องมาจากการดำเนินการตามส่วนนี้
        และผู้ใช้ยินยอมจะสละสิทธิ์ในการเรียกร้องใด ๆ ทั้งหมดที่อาจมีต่อบริษัท
        <br />
        5.6.
        ท่านสามารถใช้บริการและ/หรือลงทะเบียนเปิดบัญชีผู้ใช้ได้ต่อเมื่อท่านอาศัยอยู่ในประเทศไทยหรือประเทศที่ได้รับอนุญาตจากบริษัท
        ซึ่งจะมีการแจ้งปรับปรุงแก้ไขรายชื่อประเทศเป็นครั้งคราว
        <br />
      </dd>
      <h2>6. เงื่อนไขการใช้งาน</h2>
      <dd>
        6.1. การได้รับอนุญาตให้ใช้งาน AIYA แชทบอท และการบริการอื่น ๆ จะมีผลจนกว่าจะมีการเพิกถอน
        ยกเลิก หรือระงับสิทธิหรือการได้รับอนุญาตในการใช้งาน โดยเหตุในการเพิกถอน ยกเลิก
        หรือระงับสิทธิหรือการได้รับอนุญาตในการใช้งาน นี้จะเป็นไปตามที่ระบุไว้ในเงื่อนไขการให้บริการ
        <br />
        6.2. ท่านตกลงที่จะไม่กระทำการใด ๆ ดังต่อไปนี้
        <dd>
          6.2.1. นำเข้าเนื้อหา โพสต์ อัปโหลด เผยแพร่ ส่งต่อ ซึ่งเนื้อหาที่อาจผิดกฎหมาย
          หรืออาจเป็นภัย คุกคาม ขู่เข็ญ ล่วงละเมิด ทำให้ตื่นตกใจ ทำให้เป็นทุกข์ หลอกลวง
          ทำให้เสื่อมเสีย หยาบคาย ลามก หมิ่นประมาท ล่วงล้ำความเป็นส่วนตัวของผู้อื่น น่ารังเกียจ
          เหยียดเชื้อชาติหรือชาติพันธุ์เข้ามาสู่ AIYA แชทบอท ไม่ว่าจะผ่านทางบัญชีผู้ใช้ของตนเอง
          หรือของบุคคลอื่นก็ตาม
          <br />
          6.2.2. ละเมิดกฎหมายใด ๆ
          <br />
          6.2.3. นำเข้าเนื้อหา โพสต์ อัปโหลด เผยแพร่ ส่งต่อหรือกระทำเนื้อหาใด ๆ
          ที่เกี่ยวข้องกับการใช้ของผู้เยาว์ที่ไม่ได้อยู่ภายใต้การดูแลของผู้ปกครอง หรือ
          ใช้บริการเพื่อทำอันตรายต่อผู้เยาว์ไม่ว่าในทางตรงหรือทางอ้อม
          <br />
          6.2.4. ใช้บริการ นำเข้า โพสต์ อัปโหลด หรือเผยแพร่
          เนื้อหาเพื่อปลอมแปลงหรือแสดงให้บุคคลอื่นเชื่อหรือหลงเชื่อว่าเป็นบุคคลหรือนิติบุคคลอื่น
          หรือแสดงข้อมูลเท็จเกี่ยวกับความเชื่อมโยงกับบุคคลหรือนิติบุคคลใด
          <br />
          6.2.5. ปลอมแปลงชื่อบัญชีผู้ใช้บางส่วนหรือทั้งหมด ข้อความที่แสดงตัวตน
          หรือปรับเปลี่ยนรหัสประจำตัวเพื่อปิดบังแหล่งกำหนดของเนื้อหาที่ส่งต่อผ่านบริการ\
          <br />
          6.2.6. ลบเนื้อหาหรือประกาศเกี่ยวกับกรรมสิทธิ์ออกจาก AIYA แชทบอท
          <br />
          6.2.7. กระทำการใดๆ ที่เกี่ยวข้องกับการอนุญาตหรือมอบอำนาจให้ทำการดัดแปลง สร้างผลงาน
          หรือแปลงการบริการโดยไม่ได้รับอนุญาตเป็นลายลักษณ์จากบริษัท
          <br />
          6.2.8.
          ใช้บริการเพื่อประโยชน์ของบุคคลอื่นในลักษณะที่ไม่ได้รับอนุญาตตามสิทธิ์การใช้งานที่กำหนดไว้
          <br />
          6.2.9. ใช้บริการนำเข้า โพสต์ อัปโหลด หรือเผยแพร่เนื้อหาในลักษณะเป็นการฉ้อฉล ผิดจรรยาบรรณ
          ไม่เป็นความจริง ชี้นำไปในทางที่ผิดหรือเป็นการหลอกลวง
          <br />
          6.2.10. เปิดใช้หรือมีบัญชีผู้ใช้หลายบัญชีเพื่อกระทำการใดๆ
          ที่เป็นการละเมิดข้อกำหนดหรือวัตถุประสงค์ของข้อกำหนดเงื่อนไขการให้บริการนี้
          <br />
          6.2.11. เข้าถึงแพลตฟอร์มของ AIYA แชทบอท
          โดยการเปิดบัญชีผู้ใช้หรือเข้าถึงบัญชีผู้ใช้ของท่านโดยใช้โปรแกรมเลียนแบบ (emulator)
          โปรแกรมจำลอง (simulator) บอท (bot) หรือซอฟแวร์หรือฮาร์ดแวร์อื่นใดที่มีลักษณะคล้ายคลึงกัน
          <br />
          6.2.12. ดำเนินการใดๆ ที่อาจเป็นบ่อนทำลายระบบ AIYA แชทบอท
          <br />
          6.2.13. พยายามแยกส่วน หรือทำวิศวกรรมย้อนกลับ หรือถอดประกอบ หรือเจาะระบบบริการ
          (หรือส่วนหนึ่งส่วนใดของ AIYA แชทบอท)
          หรือพยายามเอาชนะหรือทำลายเทคโนโลยีการเข้ารหัสหรือมาตรการความปลอดภัยที่ AIYA แชทบอท
          นำมาใช้กับบริการและ/หรือข้อมูลที่ส่งต่อ ดำเนินการ หรือจัดเก็บไว้โดย AIYA แชทบอทหรือบริษัท
          <br />
          6.2.14. เก็บรวบรวม เก็บสำรอง ทำซ้ำ ใช้ เปิดเผย ข้อมูลเกี่ยวกับเจ้าของบัญชีผู้ใช้ท่านอื่น
          ซึ่งรวมถึงโดยไม่จำกัดเพียงข้อมูลส่วนบุคคล
          <br />
          6.2.15. นำเข้า โพสต์ อัปโหลด เผยแพร่ อีเมล ส่งต่อ
          หรือทำให้เนื้อหาที่ท่านไม่มีสิทธิใช้งานได้ภายใต้กฎหมายหรือภายใต้ความสัมพันธ์ตามสัญญาหรือการมอบอำนาจสามารถใช้งานได้
          ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลส่วนบุคคล ข้อมูลภายใน
          ข้อมูลที่เป็นกรรมสิทธิ์หรือข้อมูลที่เป็นความลับที่ได้ทราบหรือถูกเปิดเผยจากความสัมพันธ์ในการจ้างงานหรือภายใต้ข้อตกลงไม่เปิดเผยข้อมูลเข้าสู่
          AIYA แชทบอท
          <br />
          6.2.16. นำเข้า โพสต์ อัปโหลด เผยแพร่ อีเมล ส่งต่อ หรือทำให้เนื้อหาที่ละเมิดสิทธิบัตร
          เครื่องหมายการค้า ความลับทางการค้า ลิขสิทธิ์ หรือกรรมสิทธิ์อื่นๆ ของบุคคลอื่นเข้าสู่ AIYA
          แชทบอท
          <br />
          6.2.17. นำเข้า โพสต์ อัปโหลด เผยแพร่ อีเมล ส่งต่อ หรือทำให้โฆษณา
          หรือการประชาสัมพันธ์ที่ไม่ได้รับอนุญาต สื่อประชาสัมพันธ์ประเภทอีเมลขยะ หรือสแปม
          หรือจดหมายลูกโซ่ หรือธุรกิจแบบพีระมิด หรือธุรกิจขายตรง
          หรือรูปแบบการเชิญชวนอื่นใดที่ไม่ได้รับอนุญาต
          <br />
          6.2.18. อัปโหลด อีเมล โพสต์ ส่งต่อ หรือทำให้วัสดุอื่นๆ ที่มีไวรัสซอฟต์แวร์ หนอน ม้าโทรจัน
          หรือรหัสคอมพิวเตอร์ รูทีน ไฟล์หรือโปรแกรมที่ออกแบบมาเพื่อแทรกแซงทั้งโดยตรงและโดยอ้อมต่อ
          ปรับเปลี่ยน ขัดขวาง ทำลาย
          หรือจำกัดฟังก์ชั่นการทำงานของซอฟต์แวร์คอมพิวเตอร์หรือฮาร์ดแวร์ใดๆ
          หรือข้อมูลหรืออุปกรณ์โทรคมนาคม
          <br />
          6.2.19. ทำให้กระบวนการไหลปกติของบทสนทนาหยุดชะงัก ทำให้หน้าจอ
          เลื่อนไปมาเร็วกว่าที่ผู้ใช้บริการท่านอื่น ๆ จะสามารถพิมพ์ได้
          หรือกระทำการในลักษณะที่ส่งผลเชิงลบต่อความสามารถของผู้ใช้ท่านอื่นในการเข้าร่วมการแลกเปลี่ยนข้อมูลตามเวลาจริง
          <br />
          6.2.20. แทรกแซง ปรับเปลี่ยน
          หรือขัดขวางบริการหรือเซิร์ฟเวอร์หรือเครือข่ายที่เชื่อมต่อกับบริการหรือการใช้งานของผู้ใช้ท่านอื่นและการฝ่าฝืนข้อกำหนด
          กระบวนการ นโยบาย หรือข้อบังคับของเครือข่ายที่เชื่อมต่อกับ AIYA แชทบอท
          <br />
          6.2.21. ดำเนินการหรือเข้าร่วมในการกระทำที่อาจส่งผลเสียหายทั้งทางตรงหรือทางอ้อม ปิดใช้งาน
          สร้างภาระหนัก หรือทำให้บริการหรือเซิร์ฟเวอร์หรือเครือข่ายที่เชื่อมต่อกับบริการมีข้อบกพร่อง
          <br />
          6.2.22. ใช้บริการโดยฝ่าฝืนกฎหมาย กฎระเบียบ ประมวลกฎหมาย คำสั่ง แนวทางปฏิบัติ นโยบาย
          หรือข้อบังคับของท้องถิ่น ของรัฐ ประเทศ หรือระหว่างประเทศที่เกี่ยวข้อง ทั้งนี้ไม่จำกัดเฉพาะ
          กฎหมายและข้อกำหนด เกี่ยวกับการป้องกันการฟอกเงินหรือการต่อต้านการก่อการร้าย
          <br />
          6.2.23. ใช้บริการเพื่อฝ่าฝืนหรือหลบเลี่ยง
          การคว่ำบาตรหรือการห้ามค้าขายที่ดำเนินการหรือบังคับใช้โดยสำนักควบคุมสินทรัพย์ต่างประเทศของกระทรวงการคลังสหรัฐฯ
          คณะมนตรีความมั่นคงแห่งสหประชาชาติ กระทรวงการคลังสหภาพยุโรปหรือสหราชอาณาจักร
          <br />
          6.2.24. ใช้บริการเพื่อฝ่าฝืนความเป็นส่วนตัวของผู้อื่น หรือ
          เป็นการใช้บริการเพื่อติดตามผู้อื่นในเชิงที่เป็นการรบกวนการอยู่อย่างเป็นปกติสุขของผู้นั้น
          หรือเพื่อก่อกวนผู้อื่น
          <br />
          6.2.25. การละเมิดสิทธิต่าง ๆ
          ของบริษัทซึ่งรวมถึงสิทธิในทรัพย์สินทางปัญญาและการลวงขายในเรื่องนั้น ๆ ด้วย
          <br />
          6.2.26.
          ใช้บริการเพื่อเก็บรวบรวมหรือจัดเก็บข้อมูลส่วนบุคคลเกี่ยวกับผู้ใช้คนอื่นที่เกี่ยวกับการกระทำและกิจกรรมต้องห้ามที่ระบุไว้ด้านบน
          และ/หรือ
          <br />
          6.2.27. ลงรายการที่ละเมิดลิขสิทธิ์ เครื่องหมายการค้า
          หรือสิทธิในทรัพย์สินทางปัญหาของบุคคลภายนอกหรือ
          ใช้บริการในลักษณะที่จะละเมิดสิทธิ์ในทรัพย์สินทางปัญญาของผู้อื่น
          <br />
        </dd>
        6.3. ท่านเข้าใจว่าเนื้อหาทั้งหมด ไม่ว่าจะเป็นการโพสต์ต่อสาธารณะหรือการส่งต่อแบบเป็นส่วนตัว
        เป็นความรับผิดชอบของผู้ที่เป็นต้นกำเนิดของเนื้อหาดังกล่าวแต่เพียงผู้เดียว ซึ่งหมายความว่า
        ท่านต้องรับผิดชอบต่อเนื้อหาทั้งหมดที่ท่านอัปโหลด โพสต์ อีเมล ส่งต่อ หรือทำให้ใช้งานได้ทาง
        AIYA แชทบอท แต่เพียงผู้เดียว บริษัทไม่สามารถควบคุมเนื้อหาที่โพสต์ หรือนำเข้าสู่ AIYA
        แชทบอทได้ ดังนั้นบริษัทจึงไม่รับประกันความถูกต้อง ความสมบูรณ์ หรือคุณภาพของเนื้อหาดังกล่าว
        ท่านเข้าใจเป็นอย่างดีว่าในการใช้ AIYA แชทบอท
        ท่านอาจพบเจอกับเนื้อหาที่อาจถือได้ว่าสร้างความขุ่นเคืองใจ ลามกอนาจารหรือน่ารังเกียจ
        ตามขอบเขตกฎหมายที่เกี่ยวข้องอนุญาตไว้ ไม่ว่าในกรณีใดก็ตาม
        บริษัทจะไม่รับผิดไม่ว่าในทางใดต่อเนื้อหา รวมถึงแต่ไม่จำกัดเพียง ข้อผิดพลาด
        หรือการละเว้นเนื้อหา หรือการสูญเสียหรือความเสียหายชนิดใดก็ตามที่เกิดขึ้นอันเป็นผลมาจากการใช้
        หรือการพึ่งพาเนื้อหาที่โพสต์ อีเมล ส่งต่อ หรือพบเห็นได้บน AIYA แชท บอท
        <br />
        6.4. ท่านรับทราบว่าบริษัท อาจไม่คัดกรองเนื้อหา แต่บริษัทและผู้ที่ได้รับการแต่งตั้งจากบริษัท
        มีสิทธิตามที่เห็นสมควรแต่เพียงผู้เดียวของตนในการคัดกรอง ปฏิเสธ ลบหรือย้ายเนื้อหาใด ๆ
        ที่ใช้งานได้บน AIYA แชทบอท บริษัทและผู้ที่ได้รับการแต่งตั้งจากบริษัท มีสิทธิที่จะลบเนื้อหา
        <dd>
          6.4.1. ถ้าบริษัทและผู้ที่ได้รับการแต่งตั้งจากบริษัท
          พบว่าเป็นเนื้อหาที่ละเมิดเงื่อนไขการให้บริการ
          <br />
          6.4.2. ถ้าบริษัทและผู้ที่ได้รับการแต่งตั้งจากบริษัท ได้รับคำร้องเรียนจากผู้ใช้คนอื่น
          <br />
          6.4.3. ถ้าบริษัทและผู้ที่ได้รับการแต่งตั้งจากบริษัท
          ได้รับแจ้งเกี่ยวกับการละเมิดทรัพย์สินทางปัญญาหรือคำสั่งทางกฎหมายให้ลบออก หรือ
          <br />
          6.4.4. ถ้าเนื้อหาดังกล่าวอาจพิจารณาได้ว่า อาจเป็นที่เสื่อมเสีย หรือน่ารังเกียจ
          บริษัทอาจปิดกั้นการส่งเนื้อหาหรือข้อความเพื่อการสื่อสาร
        </dd>
        6.5. ท่านรับทราบ ยินยอม และตกลงว่าบริษัท อาจเข้าถึง รักษา
        และเปิดเผยข้อมูลบัญชีของท่านและเนื้อหาตามสิทธิที่กฎหมายกำหนดไว้เช่นนั้น
        หรือตามคำสั่งศาลหรือโดยหน่วยงานรัฐหรือหน่วยงานทางกฎหมายที่เกี่ยวข้องกับการให้บริการ AIYA
        แชทบอท
        หรือโดยการเชื่อโดยสุจริตว่าการเข้าถึงเพื่อรักษาหรือเปิดเผยนั้นมีความจำเป็นตามสมควรเพื่อ
        <dd>
          6.5.1. ปฏิบัติตามขั้นตอนทางกฎหมาย
          <br />
          6.5.2. บังคับใช้เงื่อนไขการให้บริการนี้
          <br />
          6.5.3. ตอบสนองต่อข้อเรียกร้องที่ว่าเนื้อหาใดละเมิดสิทธิของบุคคลภายนอก
          <br />
          6.5.4. ปกป้องสิทธิ ทรัพย์สินหรือความปลอดภัยส่วนบุคคลของบริษัท ผู้ใช้และ/หรือประชาชนทั่วไป
        </dd>
      </dd>
      <h2>7. ผลของการละเมิดเงื่อนไขการให้บริการ</h2>
      <dd>
        7.1. การละเมิดนโยบายนี้อาจส่งผลให้มีการดำเนินการหลายประการ รวมถึงแต่ไม่จำกัดเพียง
        สิ่งใดสิ่งหนึ่งหรือทั้งหมดต่อไปนี้
        <dd>
          7.1.1. บัญชีผู้ใช้ถูกลบ หรือถูกนำออกจากระบบ
          <br />
          7.1.2. ถูกจำกัดสิทธิในการใช้งานบางประการ ขึ้นอยู่กับการพิจารณาของผู้ให้บริการ
          <br />
          7.1.3. บัญชีผู้ใช้ถูกระงับและอาจถูกยกเลิกในภายหลัง
          <br />
          7.1.4. ถูกดำเนินคดีอาญา
          <br />
          7.1.5. ถูกดำเนินคดีทางแพ่ง
          รวมถึงแต่ไม่จำกัดเพียงการเรียกร้องค่าเสียหายและ/หรือวิธีการชั่วคราว
          ก่อนพิพากษาหรือคำสั่งห้ามของศาล
        </dd>
        7.2. หากท่านเชื่อว่าผู้ใช้บน AIYA แชทบอท ละเมิดเงื่อนไขการให้บริการนี้ โปรดติดต่อมาที่อีเมล
        hello@aiya.ai
      </dd>
      <h2>8. การแจ้งข้อมูลการละเมิดทรัพย์สินทางปัญญา</h2>
      <dd>
        8.1. ผู้ใช้เป็นบุคคลหรือธุรกิจที่เป็นอิสระและไม่เกี่ยวข้องกับบริษัท ไม่ว่าในทางใด
        บริษัทมิใช่ตัวแทนหรือผู้แทนของผู้ใช้
        <br />
        8.2. หากท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญา
        หรือตัวแทนที่ได้รับอนุญาตอย่างถูกต้องจากเจ้าของสิทธิในทรัพย์สินทางปัญญา
        และท่านเชื่อว่าสิทธิของท่านหรือสิทธิตัวการของท่านได้ถูกละเมิด
        โปรดทำหนังสือแจ้งให้บริษัททราบเป็นลายลักษณ์อักษรโดยส่งทางอีเมลมาที่อีเมล hello@aiya.ai
      </dd>
      <h2>9. กฎหมายที่ใช้บังคับ</h2>
      <dd>
        ข้อกำหนดว่าด้วยการให้บริการฉบับนี้มีผลบังคับตามกฎหมายแห่งราชอาณาจักรไทยและคู่สัญญาทั้งสองฝ่ายตกลงโดยไม่สามารถเพิกถอนได้ให้ศาลแห่งราชอาณาจักรไทยเป็นศาลที่มีเขตอำนาจเฉพาะแต่เพียงศาลเดียว
      </dd>
    </div>
  )
}

export default Terms
